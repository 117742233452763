.table-header-cell {
    font-weight: bold;
    font-size: 14px;
    color: #455A64;
  }
 
.table-body-cell {
    font-size: 14px;
    font-weight: 400;
    color: #A4A2A2;
}  
.active {
  font-weight: bold;
  color: #00CD5B; /* Or any color you prefer for the active page */
}

.unactive {
  color: #A4A2A2; /* Color for inactive pages */
}