.table-header-cell {
    font-weight: bold;
    font-size: 14px;
    color: #455A64;
  }
 
.table-body-cell {
    font-size: 14px;
    font-weight: 400;
    color: #A4A2A2;
}  

.draggable-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
}

.switch {
  position: relative;
  width: 60px;
  height: 34px;
  background-color: #f44336; /* Red for disabled state */
  border-radius: 34px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.4s;
}

.slider {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  transition: 0.4s;
}

.slider.enabled {
  left: 30px;
}

.slider.dragging {
  transition: none;
}

.switch.enabled {
  background-color: #ddd; /* Lighter background when enabled */
}

p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

