.player {
    display: flex;
    align-items: center;
    width: 200px;
  }
  
  
  .timing-bar {
    width: 100%;
    height: 8px;
    background-color: #ccc;
    margin-left: 16px;
  }
  
  .timing-bar-progress {
    height: 100%;
    background-color: #333;
    transition: width 0.2s ease-in-out;
  }
  