.react-datepicker__day--selected {
    background-color: #00CD5B;
    color: white;
  }
  
  .react-datepicker__day--in-range {
    background-color: #00CD5B;
    color: white;
  }
  .react-datepicker__day--in-range:hover {
    background-color: #63dd99;
    color: white;
  }
  .react-datepicker__close-icon::after {
    background-color: black;
  }
  