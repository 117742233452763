@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.active {
  color: #00cd5b;
  font-weight: bold;
  /* Add any other styles you want for the active menu item */
}
